.modal_wrap {
  .modalbody_wrap {
    .modalInnerBody_wrap {

      &.shareModal_wrap {
        max-width: 800px;

        .userSelect_wrap {
          >div {
            &.userList_noSelected_wrap {
              flex: 1;

              .userList_noSelected {
                border: 1px solid #E2E3E2;
                border-radius: 5px;
                box-sizing: border-box;
                padding: 10px;
                height: 210px;
                overflow-y: auto;

                >div {
                  cursor: pointer;
                  background-color: #ffffff;
                  padding: 5px;
                  border-bottom: 1px solid #FFD9B9;

                  &:hover {
                    background-color: #FFD9B9;
                  }
                }
              }
            }
            &.imgArrow {
              flex-basis: 50px;

              img {
                &.img_pc {display: block;}
                &.img_sp {display: none;}
              }
            }
            &.userList_selected_wrap {
              flex: 1;

              .userList_selected {
                border: 1px solid #E2E3E2;
                border-radius: 5px;
                box-sizing: border-box;
                padding: 10px;
                height: 263px;
                // height: calc(100% - 32px);
                overflow-y: auto;

                >div {
                  cursor: pointer;
                  background-color: #ffffff;
                  padding: 5px;
                  border-bottom: 1px solid #FFD9B9;

                  &:hover {
                    background-color: #FFD9B9;
                  }
                }
              }
            }
          }
        }//.userSelect_wrap

        @media screen and (max-width: 767px) {
          .userSelect_wrap > div {
            &.userList_noSelected_wrap .userList_noSelected {
              height: 170px;
            }
            &.userList_selected_wrap .userList_selected {
              // max-height: 120px;
              height: 210px;
            }
          }
        }
        @media screen and (max-width: 479px) {
          .userSelect_wrap {
            display: block;

            >div {
              &.userList_noSelected_wrap .userList_noSelected {
                height: 120px;
              }
              &.userList_selected_wrap .userList_selected {
                // max-height: 120px;
                height: 120px;
              }
              &.imgArrow {
                margin: 10px 0;

                img {
                  &.img_pc {display: none;}
                  &.img_sp {display: block;}
                }
              }
            }
          }
          .btn_wrap {
            -webkit-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-wrap: wrap;

            >div {
              flex-basis: 100%;
              width: 100%;

              &:first-of-type {
                order: 2;
                margin-top: 10px;
              }
              &:last-of-type {
                order: 1;
              }

              button {
                margin: 0 auto;
              }
            }
          }
        }
      }//.shareModal_wrap

      //選択確認画面
      &.shareModalSelected_wrap {
        .shareModalSelectedInner {
          .selectedUserList {
            border: 1px solid #E2E3E2;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 10px;
            //max-height: 210px;
            height: 263px;
            overflow-y: auto;

            >div {
              background-color: #ffffff;
              padding: 5px;
              border-bottom: 1px solid #FFD9B9;
            }
          }
        }
        @media screen and (max-width: 767px) {
          .shareModalSelectedInner {
            .selectedUserList {
              height: 210px;
            }
          }
        }
        @media screen and (max-width: 479px) {
          .shareModalSelectedInner .selectedUserList {
            height: 120px;
          }
          .btn_wrap {
            -webkit-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-wrap: wrap;

            >div {
              flex-basis: 100%;
              width: 100%;

              &:first-of-type {
                order: 2;
                margin-top: 10px;
              }
              &:last-of-type {
                order: 1;
              }

              button {
                margin: 0 auto;
              }
            }
          }
        }
      }//.shareModalSelected_wrap

    }//.modalInnerBody_wrap
  }//.modalbody_wrap
}//.modal_wrap