.menu_wrap {
  .menuInner_wrap {
    position: relative;
    height: 100%;

    .menuItem {
      padding: 10px 0 10px 10px;
      background-color: #3F3F3F;
      border-bottom: 2px solid #252525;
      position: relative;

      &.menuItemSetting {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: none;
        border-top: 2px solid #252525;
      }
  
      >div {
        &:first-of-type {
          flex-basis: 32px;
          width: 32px;
        }
        &:nth-of-type(2) {
          flex: 1;
          color: #ffffff;
          margin-left: 10px;
          text-align: left;
        }
      }
  
      &:hover {
        background-color: #565655;
        -webkit-transition: 0.2s ease-in-out;
        -moz-transition: 0.2s ease-in-out;
        -o-transition: 0.2s ease-in-out;
        transition: 0.2s ease-in-out;
      }

      .arrow_active {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        color: #ffffff;
        font-size: 20px;

        display: none;
      }

      &.active {
        background-color: #565655;

        .arrow_active {
          display: block;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .menuInner_wrap .menuItem > div {
      &:first-of-type {
        flex-basis: 25px;
        width: 25px;
      }
      &:nth-of-type(2) {
        font-size: 13px;
        margin-left: 5px;
      }
      &.arrow_active {
        font-size: 16px;
      }
    }
  }
  @media (max-width: 767px) {}
  @media (max-width: 350px) {}
}