.content_wrap {
  &.cont_article_list {

    .searchArea {
      .searchDefault_wrap {
        .left_wrap {
          flex-basis: 380px;
          width: 380px;

          > div {
            &:first-of-type {
            }

            &.input_wrap {
              flex: 1;
              margin-left: 10px;
            }
          }
        }

        .right_wrap {
          flex: 1;
          margin-left: 40px;

          > div {
            &.input_wrap {
              flex: 1;
              margin-left: 10px;

              > div {
                &:first-of-type {
                  flex-basis: 150px;
                  width: 150px;
                }

                &:last-of-type {
                  flex-basis: 150px;
                  width: 150px;
                }
              }
            }
          }

          br {
            display: none;
          }
        }
      }

      //.searchDefault_wrap

      .btn_wrap {
        min-height: 30px;

        .btn_searchMore_wrap {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);

          button {
            font-size: 16px;
          }
        }
      }

      .searchDetail_wrap {
        margin-top: 20px;
        border-top: 1px solid #E2E3E2;
        padding-top: 20px;

        .input_col_wrap {
          .input_1col_wrap {
            &:last-of-type {
              margin-left: 40px;
            }

            &:first-of-type {
              margin-left: 0;
            }

            > div {
              &:last-of-type {
                flex: 1;
                margin-left: 10px;

                > div {
                  &:first-of-type,
                  &:last-of-type {
                    flex: 1;
                  }
                }
              }
            }
          }

          &:first-of-type {
            .input_1col_wrap {
              flex-basis: 320px;
              width: 320px;
            }
          }

          &:nth-of-type(2) {
            margin-top: 20px;

            .input_1col_wrap {
              flex-basis: 320px;
              width: 320px;

              > div:last-of-type {
                > div input {
                  flex: 1;
                }
              }
            }
          }

          &:nth-of-type(3) {
            margin-top: 20px;

            .input_1col_wrap {
              flex-basis: 340px;
              width: 340px;
            }
          }

          &:nth-of-type(4) {
            margin-top: 20px;

            .input_1col_wrap {
              flex-basis: 70%;
              width: 70%;
            }
          }

          &:nth-of-type(5) {
            margin-top: 20px;

            .input_1col_wrap {
              flex-basis: 340px;
              width: 340px;
            }
          }
        }
      }

      //.searchDetail_wrap

      .space_wrap {
        flex-basis: 30px;
        width: 30px;
        text-align: center;
      }

    }

    //.searchArea

    .tableArea {
      width: calc(100% - 60px);
      overflow-x: scroll;
      padding: 0 !important;
      margin: 0 auto;
    }

    .table_wrap {
      display: table;
      table-layout: fixed;
      width: 100%;
      min-width: 1300px;
      border-collapse: collapse;

      .table_row {
        display: table-row;
        background-color: #ffffff;

        &:nth-of-type(odd) {
          background-color: #F7F7F7 !important;
        }

        .table_cell {
          display: table-cell;
          border: 1px solid #E2E3E2;
          padding: 10px 0;
          text-align: center;
          vertical-align: middle;
        }

        //.table_cell

        &.table_head {
          .table_cell {
            background-color: #ED6C00;
            color: #ffffff;
            font-size: 14px;
            font-weight: bold;

            &:first-of-type {
              width: 80px;
            }

            &:nth-of-type(2) {
              width: 50px;
            }

            &:nth-of-type(3) {
              width: 120px;
            }

            &:nth-of-type(4) {
              width: auto;
            }

            &:nth-of-type(5) {
              width: 80px;
            }

            &:nth-of-type(6) {
              width: 110px;
            }

            &:nth-of-type(7) {
              width: 100px;
            }

            &:nth-of-type(8) {
              width: 75px;
            }

            &:nth-of-type(9) {
              width: 105px;
            }

            &:nth-of-type(10) {
              width: 80px;
            }

            &:nth-of-type(11) {
              width: 110px;
            }

            &:nth-of-type(12) {
              width: 100px;
            }

            &:last-of-type {
              width: 105px;
            }
          }
        }

        //.table_head
        &.table_body {
          cursor: pointer;

          &:hover {
            background-color: #FFDCC5 !important;
            -webkit-transition: 0.2s ease-in-out;
            -moz-transition: 0.2s ease-in-out;
            -o-transition: 0.2s ease-in-out;
            transition: 0.2s ease-in-out;
          }

          .table_cell {
            font-size: 14px;
            padding: 10px 5px;

            > div {
              &.sp_table_head {
                display: none;
              }

              &.table_cell_content {

                //販売状況
                > div {
                  color: #ffffff;
                  padding: 10px 0;
                  border-radius: 5px;

                  &.sales_status_1 {
                    background-color: #4eb9e5;
                  }

                  &.sales_status_2 {
                    background-color: #4e89c8;
                  }

                  &.sales_status_3 {
                    background-color: #eaa75f;
                  }

                  &.sales_status_4 {
                    background-color: #6bba53;
                  }

                  &.sales_status_5 {
                    background-color: #7a6eb0;
                  }

                  &.sales_status_6 {
                    background-color: #8d8d8e;
                  }

                  &.sales_status_7 {
                    background-color: #e94a49;
                  }
                }
              }
            }
          }

          &.sales_finished {
            opacity: 0.8;
            background-color: #dddddd !important;
          }
        }

        //.table_body
      }

      //.table_row
    }

    //.table_wrap

    @media (max-width: 1199px) {
      .searchArea .searchDefault_wrap {
        .left_wrap {
          flex-basis: 300px;
          width: 300px;
        }

        .right_wrap {
          > div {
            &.input_wrap {
              > div {
                &:first-of-type,
                &:last-of-type {
                  //flex: 1;
                  width: 140px;
                }
              }
            }
          }

          br {
            &.br_992_1199 {
              display: block;
            }
          }
        }
      }

      .table_wrap {
        min-width: 1200px;

        .table_row {
          &.table_head .table_cell {
            font-size: 13px;

            &:first-of-type {
              width: 70px;
            }

            &:nth-of-type(2) {
              width: 50px;
            }

            &:nth-of-type(3) {
              width: 110px;
            }

            &:nth-of-type(4) {
              width: auto;
            }

            &:nth-of-type(5) {
              width: 75px;
            }

            &:nth-of-type(6) {
              width: 100px;
            }

            &:nth-of-type(7) {
              width: 95px;
            }

            &:nth-of-type(8) {
              width: 65px;
            }

            &:nth-of-type(9) {
              width: 95px;
            }

            &:nth-of-type(10) {
              width: 75px;
            }

            &:nth-of-type(11) {
              width: 110px;
            }

            &:nth-of-type(12) {
              width: 95px;
            }

            &:last-of-type {
              width: 95px;
            }
          }

          &.table_body .table_cell {
            font-size: 13px;
          }
        }
      }
    }
    @media (max-width: 991px) {
      .searchArea {
        .searchDefault_wrap {
          display: block;

          .right_wrap {
            margin-top: 20px;
            margin-left: 0;

            > div.input_wrap > div {
              &:first-of-type,
              &:last-of-type {
                max-width: 140px;
              }
            }

            br {
              &.br_992_1199 {
                display: none;
              }
            }
          }
        }

        .btn_wrap .btn_searchMore_wrap button {
          font-size: 14px;
        }

        .searchDetail_wrap .input_col_wrap {
          &:first-of-type .input_1col_wrap {
            flex: 1;
            flex-basis: auto;
            width: auto;
          }

          &:nth-of-type(3) .input_1col_wrap {
            flex: 1;
            flex-basis: auto;
            width: auto;
          }

          .input_1col_wrap {
            &:last-of-type {
              // margin-left: 20px;
            }
          }
        }
      }
      .tableArea {
        width: calc(100% - 40px);
      }

      .table_wrap {
        min-width: 1050px;

        .table_row {
          &.table_head .table_cell {
            font-size: 10px;

            &:first-of-type {
              width: 60px;
            }

            &:nth-of-type(2) {
              width: 45px;
            }

            &:nth-of-type(3) {
              width: 90px;
            }

            &:nth-of-type(4) {
              width: auto;
            }

            &:nth-of-type(5) {
              width: 65px;
            }

            &:nth-of-type(6) {
              width: 85px;
            }

            &:nth-of-type(7) {
              width: 80px;
            }

            &:nth-of-type(8) {
              width: 75px;
            }

            &:nth-of-type(9) {
              width: 80px;
            }

            &:nth-of-type(10) {
              width: 65px;
            }

            &:nth-of-type(11) {
              width: 100px;
            }

            &:nth-of-type(12) {
              width: 80px;
            }

            &:last-of-type {
              width: 80px;
            }
          }

          &.table_body .table_cell {
            font-size: 10px;
          }
        }
      }
    }
    @media (max-width: 767px) {
      .searchArea {
        .searchDefault_wrap {
          .left_wrap {
            flex: 1;
            width: 100%;
          }

          .right_wrap {
            margin-top: 10px;
          }
        }

        .searchDetail_wrap .input_col_wrap {
          display: block;

          &:nth-of-type(2) {
            margin-top: 10px;

            .input_1col_wrap {
              width: 100%;
            }
          }

          &:nth-of-type(3) {
            margin-top: 10px;
          }

          &:nth-of-type(4) {
            margin-top: 10px;

            .input_1col_wrap {
              width: 100%;
            }
          }

          .input_1col_wrap {
            &:last-of-type {
              margin-left: 0;
              margin-top: 10px;
            }
          }
        }
      }

      .table_wrap {
        display: block;
        min-width: 1px;

        .table_row {
          display: block;

          &:not(:first-of-type) {
            margin-top: 20px;
          }

          .table_cell {
            display: block;
          }

          &.table_head {
            display: none;
          }

          &.table_body {
            .table_cell {
              -js-display: flex;
              display: -webkit-flex;
              display: -ms-flexbox;
              display: -webkit-box;
              display: flex;
              padding: 0;

              &:not(:last-of-type) {
                border-bottom: none;
              }

              &:last-of-type {
                .sp_table_head {
                  padding-top: 15px;
                }
              }

              > div {
                &.sp_table_head {
                  display: block;
                  flex-basis: 150px;
                  width: 150px;
                  background-color: #ED6C00;
                  color: #ffffff;
                  font-size: 14px;
                  font-weight: bold;
                  padding: 10px;
                }

                &.table_cell_content {
                  flex: 1;
                  font-size: 14px;
                  padding: 10px;
                  border-left: 1px solid #E2E3E2;
                  text-align: left;

                  > div {
                    display: inline-block;
                    padding: 5px 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 479px) {
      .searchArea {
        .searchDefault_wrap {
          .left_wrap {
            display: block;

            > div.input_wrap {
              margin-left: 0;
              margin-top: 5px;
            }
          }

          .right_wrap {
            display: block;

            > div.input_wrap {
              margin-left: 0;
              margin-top: 5px;

              > div {
                &:first-of-type,
                &:last-of-type {
                  max-width: none;
                  width: 100%;
                }
              }
            }
          }
        }

        .btn_wrap {
          display: block;

          .btn_search_wrap {
            button {
              margin: 0 auto;
            }
          }

          .btn_searchMore_wrap {
            position: relative;
            top: auto;
            transform: none;

            button {
              margin: 20px auto 0;
            }
          }
        }

        .searchDetail_wrap .input_col_wrap .input_1col_wrap {
          display: block;

          > div:last-of-type {
            margin-left: 0;
            margin-top: 5px;
          }
        }
      }

      .table_wrap .table_row {
        &:nth-of-type(odd) {
          background-color: #ffffff !important;
        }

        &.table_body .table_cell > div {
          &.sp_table_head {
            flex-basis: 125px;
            width: 125px;
            font-size: 12px;
          }

          &.table_cell_content {
            font-size: 12px;
          }
        }
      }
    }
    @media (max-width: 350px) {
      .searchArea .searchDefault_wrap .right_wrap > div.input_wrap {
        -webkit-box-lines: multiple;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-wrap: wrap;

        > div {
          &:first-of-type {
            flex-basis: 100%;
            width: 100%;
          }

          &:not(:first-of-type) {
            margin-top: 10px;
          }
        }
      }
      .table_wrap .table_row {
        &.table_body .table_cell > div {
          &.sp_table_head {
            flex-basis: 110px;
            width: 110px;
            font-size: 10px;
            padding: 10px 5px;
          }

          &.table_cell_content {
            font-size: 10px;
            padding: 10px 5px;
          }
        }
      }
    }

    //不動産紹介
    &.introduced {
      .table_wrap {
        min-width: 950px;

        .table_row.table_head .table_cell {
          &:first-of-type {
            width: 130px;
          }

          &:nth-of-type(2) {
            width: auto;
          }

          &:nth-of-type(3) {
            width: 90px;
          }

          &:nth-of-type(4) {
            width: 100px;
          }

          &:nth-of-type(5) {
            width: 100px;
          }

          &:nth-of-type(6) {
            width: 70px;
          }

          &:last-of-type {
            width: 110px;
          }
        }
      }

      @media (max-width: 1199px) {
        .table_wrap {
          min-width: 750px;

          .table_row.table_head .table_cell {
            &:first-of-type {
              width: 125px;
            }

            &:nth-of-type(2) {
              width: auto;
            }

            &:nth-of-type(3) {
              width: 80px;
            }

            &:nth-of-type(4) {
              width: 95px;
            }

            &:nth-of-type(5) {
              width: 95px;
            }

            &:nth-of-type(6) {
              width: 65px;
            }

            &:last-of-type {
              width: 95px;
            }
          }
        }
      }
      @media (max-width: 991px) {
        .table_wrap {
          min-width: 600px;

          .table_row.table_head .table_cell {
            &:first-of-type {
              width: 100px;
            }

            &:nth-of-type(2) {
              width: auto;
            }

            &:nth-of-type(3) {
              width: 65px;
            }

            &:nth-of-type(4) {
              width: 75px;
            }

            &:nth-of-type(5) {
              width: 85px;
            }

            &:nth-of-type(6) {
              width: 55px;
            }

            &:last-of-type {
              width: 75px;
            }
          }
        }
      }
      @media (max-width: 767px) {
        .table_wrap {
          min-width: 1px;
        }
      }
    }

  }

  //.cont_article_list
}

//.content_wrap
