.modal_wrap {
  .modalbody_wrap {
    .modalInnerBody_wrap {

      &.articleCopyModal_wrap {
        max-width: 600px;

        .table_wrap {
          border-collapse: collapse;

          .table_row {
            .table_cell {
              font-size: 16px;
              padding: 10px;
              border: 1px solid #E2E3E2;

              &:first-of-type {
                background-color: #ED6C00;
                color: #ffffff;
                width: 180px;
              }
              &:last-of-type {
                input {
                  &:disabled {
                    background-color: #E5E5E5;
                    border: 1px solid #CBCCCB;
                  }
                }
              }
            }
          }//.table_row
        }//.table_wrap

        .btn_wrap{
          >div {
            button {
              &:disabled {
                opacity: 0.4;
                cursor: default;
                pointer-events: none;
              }
            }
            &:first-of-type {}
            &:last-of-type {
              button {
                width: auto;
                padding: 0 20px;
                display: flex;

                >span {
                  display: block;
                  margin-left: 10px;
                }
              }
            }
          }
        }

        @media screen and (max-width: 991px) {
          .table_wrap .table_row .table_cell {
            font-size: 14px;
          }
        }
        @media screen and (max-width: 767px) {
          .table_wrap {
            display: block;

            .table_row {
              display: block;

              .table_cell {
                display: block;
                width: 100%!important;
              }
            }
          }
        }
        @media screen and (max-width: 479px) {
          .table_wrap .table_row .table_cell {
            font-size: 10px;
          }
          .btn_wrap {
            -webkit-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-wrap: wrap;
            >div {
              flex-basis: 100%;
              width: 100%;

              &:first-of-type {
                order: 2;
                margin-top: 10px;
              }
              &:last-of-type {
                order: 1;
              }

              button {
                margin: 0 auto;
                width: 156px;
              }
            }
          }
        }
      }//.reservationListModal_wrap

    }//.modalInnerBody_wrap
  }//.modalbody_wrap
}//.modal_wrap