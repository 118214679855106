.modal_wrap {
  .modalbody_wrap {
    .modalInnerBody_wrap {
      &.documentModal_wrap {
        max-width: 800px;
        max-height: 615px;

        .table_wrap {
          border-collapse: collapse;

          .table_row {
            .table_cell {
              font-size: 16px;
              padding: 10px;
              border: 1px solid #E2E3E2;

              &:first-of-type {
                background-color: #ED6C00;
                color: #ffffff;
                width: 180px;
              }
              &:last-of-type {
              }

            }//.table_cell
          }//.table_row
        }//.table_wrap

        .documentList_wrap {
          border: 1px solid #E2E3E2;
          max-height: 275px;
          overflow-y: auto;

          .documentListInner_wrap {
            padding: 10px;

            &:not(:first-of-type) {
              border-top: 1px solid #E2E3E2;
            }

            >div {
              &:first-of-type {
                //チェックボックス
                flex-basis: 30px;
                width: 30px;
              }
              &:nth-of-type(2) {
                //ファイル名
                flex: 1;
                margin: 0 10px;
              }
              &:nth-of-type(3) {
                //名前
                flex-basis: auto;
              }
              /**
               * もしかしたら4つ目に日時が来るかもしれないのでlast-of-typeにはしないでおく
               */
            }
          }
        }//.documentList_wrap

        .btn_wrap {
          >div {
            &.flex_box {
              >div {
                &:first-of-type {
                  margin-right: 10px;
                }
              }
            }
          }
          button {
            width: 130px;
          }
        }

        @media screen and (max-width: 991px) {
          .table_wrap .table_row .table_cell {
            font-size: 14px;
          }
        }
        @media screen and (max-width: 767px) {
          max-height: none;

          .table_wrap {
            display: block;

            .table_row {
              display: block;

              .table_cell {
                display: block;
                width: 100% !important;
              }
            }
          }
          .btn_wrap button {
            width: 110px;
          }
        }
        @media screen and (max-width: 479px) {
          .table_wrap .table_row .table_cell {
            font-size: 10px;
          }
          .btn_wrap {
            -webkit-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-wrap: wrap;

            >div {
              flex: 100%;
              width: 100%;

              &:first-of-type {
                order: 2;
                margin-top: 10px;
              }

              &.flex_box {
                order: 1;
                -webkit-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-wrap: wrap;

                >div {
                  flex-basis: 100%;
                  width: 100%;

                  &:first-of-type {
                    order: 2;
                    margin-right: 0;
                    margin-top: 10px;
                  }
                  &:last-of-type {
                    order: 1;
                  }
                }
              }
              button {
                margin: 0 auto;
                width: 156px;
              }
            }
          }
        }

      }//.reservationEditModal_wrap

    }//.modalInnerBody_wrap
  }//.modalbody_wrap
}//.modal_wrap