@import "../article-detail/article-detail-component.scss";

.body_wrap {
  &.advanceReservation_body {
    height: 100%;

    .content_wrap {
      &.cont_article_detail {
        height: 100%;

        .contentInner_wrap {
          width: 100%;
          max-width: 980px;
          margin: 0 auto;

          .tableArea {
            .invalid-message {
              color: #ff0000;
            }
            .tableInner_wrap > .flex_box > div {
              &.padd_t_adjust {
                padding-top: 20px;
              }
              &:first-of-type {
                flex-basis: 200px;
                width: 200px;
              }
              .innerSmallText {
                font-size: 80%;
              }
              &.radio_wrap {
                label {
                  cursor: pointer;

                  &:not(:first-of-type) {
                    margin-left: 20px;
                  }
                  input[type='radio'] {
                    -webkit-appearance: radio;
                  }
                }
              }
              input[type='text'] {
                width: 100%;

                &.width_name {
                  max-width: 15em;
                }
                &.width_tel {
                  max-width: 8em;
                }
              }
            }
          }//.tableArea

          .attention_wrap {
            width: 100%;
            max-width: 980px;
            margin: 0 auto;
            padding: 0 40px;

            a {
              color: #ED6C00;
              text-decoration: underline;

              &:hover {
                text-decoration: none;
              }
            }
          }

          .checkbox_wrap {
            >div {
              &:last-of-type {
                margin-left: 10px;
              }
            }
          }

          .btn_wrap {
            >div {
              margin: 0 10px;

              button {
                font-size: 16px;
                height: 45px;
                line-height: 43px;
                border-radius: 26px;
              }
            }
          }

          .completeMessage {
            width: 100%;
            max-width: 750px;
            margin: 0 auto;
            padding: 0 40px;

            .mainText {
              color: #ED6C00;
              font-size: 26px;
            }
          }//.completeMessage

        }//.contentInner_wrap
      }//.cont_article_detail
    }//.content_wrap
    
    @media (max-width: 479px) {
      .content_wrap.cont_article_detail .contentInner_wrap {
        .contentHead_wrap .contentHeadInner_wrap .contentHead_text {
          -webkit-box-lines:multiple; -webkit-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-wrap: wrap;

          > span {
            &:first-of-type {
              flex-basis: 100%;
              width: 100%;
            }
            &:not(:first-of-type) {
              margin-top: 5px;
            }
          }
        }
        .btn_wrap {
          >div {
            button {
              font-size: 12px;
              height: 30px;
              line-height: 28px;
              border-radius: 15px;
            }
          }
        }
        .tableArea .tableInner_wrap >.flex_box {
          display: block;

          > div {
            &:first-of-type {
              width: 100%;

              &.padd_t_adjust {
                padding-top: 10px;
              }
            }
            &:last-of-type {}
          }
        }
        .completeMessage .mainText {
          font-size: 20px;
        }
      }
    }

  }//.advanceReservation_body
}
