.body_wrap .content_wrap .contentInner_wrap .searchArea {
  &.masterSchool {
    .select_wrap {
      >div {
        &.left_wrap {}
        &.right_wrap {
          margin-left: 40px;
        }
        >div {
          &:last-of-type {
            margin-left: 10px;
          }
        }
      }
    }
    @media (max-width: 479px) {
      .select_wrap {
        display: block;

        > div.right_wrap {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }
}