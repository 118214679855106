.dropzone_wrap {
  .dropzoneInner {
    .background_wrap {
      border: 1px solid #ED6C00;
      background-color: #FFEAD9;
      padding: 30px 10px;
      cursor: pointer;
      max-height: 150px;
      overflow-y: auto;

      >.flex_box {
        .img_wrap {
          flex-basis: 40px;
          width: 40px;
        }
        .text_wrap {
          margin-left: 20px;
        }
        &.fileName_wrap {
          padding-left: 100px;

          .img_wrap {
            flex-basis: 25px;
            width: 25px;
          }
          .fileName {
            margin-left: 5px;
          }
          &:not(:first-of-type) {
            margin-top: 5px;
          }
        }
      }
    }
    input {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    .dropzoneInner .background_wrap > .flex_box.fileName_wrap {
      padding-left: 30px;

      .img_wrap {
        flex-basis: 18px;
        width: 18px;
      }
    }
  }
  @media screen and (max-width: 479px) {
    .dropzoneInner .background_wrap {
      padding: 20px 0;

      > .flex_box {
        .img_wrap {
          flex-basis: 25px;
          width: 25px;
        }
        .text_wrap {
          margin-left: 10px;
        }
        &.fileName_wrap {
          padding-left: 10px;

          .img_wrap {
            flex-basis: 15px;
            width: 15px;
          }
        }
      }
    }
  }
}