.pager_wrap {
  ul {
    border: 1px solid #E2E3E2;
    border-radius: 5px;

    li {
      padding: 10px 15px;
      cursor: pointer;

      &:not(:first-child) {
        border-left: 1px solid #E2E3E2;
      }
      &:hover {
        color: #ED6C00;
        text-decoration: underline;
      }
      &.active {
        color: #ED6C00;
        text-decoration: underline;
      }
    }
  }
}//.pager_wrap
@media (max-width: 1199px) {
}
@media (max-width:  991px) {
}
@media (max-width:  767px) {
  .pager_wrap {
    ul {
      li {
        padding: 5px 10px;
      }
    }
  }
}
@media (max-width:  479px) {
  .pager_wrap {
    text-align: center;

    ul {
      display: inline-flex;
    }
  }
}
@media (max-width:  350px) {
}