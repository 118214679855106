.modal_wrap {
  .modalbody_wrap {
    .modalInnerBody_wrap {
      &.reservationEditModal_wrap {
        max-width: 800px;

        .table_wrap {
          border-collapse: collapse;

          .table_row {
            .table_cell {
              font-size: 16px;
              padding: 10px;
              border: 1px solid #e2e3e2;

              &:first-of-type {
                background-color: #ed6c00;
                color: #ffffff;
                width: 180px;
              }
              &:last-of-type {
              }

              &.reservationDateCell {
                padding: 0;

                > .flex_box {
                  > div {
                    flex: 1;
                    padding: 10px;

                    &:last-of-type {
                      border-left: 1px solid #e2e3e2;
                    }

                    .react-datepicker-wrapper {
                      .react-datepicker__input-container {
                        input {
                          width: 100%;
                          max-width: 150px;
                        }
                      }
                    }
                  }
                }
              }

              &.customerNameCell {
                > .flex_box {
                  &.withButton_wrap {
                    > div {
                      &:first-of-type {
                        //flex: 1;
                        select {
                          min-width: 9em;
                        }
                      }

                      &:last-of-type {
                        flex-basis: 34px;
                        width: 34px;
                        margin-left: 20px;


                        button {
                          width: 100%;
                          height: auto;
                          padding: 5px;
                          -webkit-border-radius: 50%;
                          -moz-border-radius: 50%;
                          border-radius: 50%;

                          img {
                            width: 22px;

                            &.iconSearch {
                              display: block;
                            }

                            &.iconSearchOn {
                              display: none;
                            }
                          }

                          &:hover {
                            img {
                              &.iconSearch {
                                display: none;
                              }

                              &.iconSearchOn {
                                display: block;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  > div {
                    &:first-of-type {
                      max-width: 8rem;
                    }
                    &:last-of-type {
                      margin-left: 10px;
                    }
                  }
                }
              }
            } //.table_cell
          } //.table_row
        } //.table_wrap

        .btn_wrap {
          > div {
            &.flex_box {
              > div {
                &:first-of-type {
                  margin-right: 10px;
                }
              }
            }
          }
        }

        @media (max-width: 1199px) {
          .table_wrap .table_row .table_cell.customerNameCell > div {
            &.flex_box {
              &.withButton_wrap > div {
                &:last-of-type {
                  flex-basis: 28px;
                  width: 28px;
        
                  button {
                    padding: 4px;
                  }
                }
              }
            }
          } 
        }

        @media screen and (max-width: 991px) {
          .table_wrap .table_row .table_cell {
            font-size: 14px;

            &.customerNameCell > div {
              &.flex_box {
                &.withButton_wrap > div {
                  &:last-of-type {
                    flex-basis: 24px;
                    width: 24px;
                  }
                }
              }
            } 
          }
        }
        @media screen and (max-width: 767px) {
          .table_wrap {
            display: block;

            .table_row {
              display: block;

              .table_cell {
                display: block;
                width: 100% !important;
              }
            }
          }
        }
        @media screen and (max-width: 479px) {
          .table_wrap .table_row .table_cell {
            font-size: 10px;

            &.reservationDateCell > .flex_box {
              display: block;

              > div {
                &:last-of-type {
                  border-left: none;
                  border-top: 1px solid #e2e3e2;
                }
              }
            }
          }
          .btn_wrap {
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            -ms-flex-wrap: wrap;

            > div {
              flex: 100%;
              width: 100%;

              &:first-of-type {
                order: 2;
                margin-top: 10px;
              }

              &.flex_box {
                order: 1;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                -ms-flex-wrap: wrap;

                > div {
                  flex-basis: 100%;
                  width: 100%;

                  &:first-of-type {
                    order: 2;
                    margin-right: 0;
                    margin-top: 10px;
                  }
                  &:last-of-type {
                    order: 1;
                  }
                }
              }
              button {
                margin: 0 auto;
                width: 156px;
              }
            }
          }
        }
      } //.reservationEditModal_wrap
    } //.modalInnerBody_wrap
  } //.modalbody_wrap
} //.modal_wrap
