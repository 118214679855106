.tableArea {
  &.masterTableStyle {
    position: relative;
    padding: 0 30px;

    .sp_whiteArea {
      display: none;
    }
    .table_wrap {
      .table_row {
        display: table-row;
        background-color: #ffffff;

        &:nth-of-type(odd) {
          background-color: #F7F7F7!important;
        }

        .table_cell {
          display: table-cell;
          border: 1px solid #E2E3E2;
          padding: 10px 5px;
          vertical-align: middle;
        }//.table_cell

        &.table_head {
          .table_cell {
            background-color: #ED6C00;
            color: #ffffff;
            font-size: 16px;
            font-weight: bold;
            text-align: center;

            &:first-of-type  {width: 50px;}
            &:nth-of-type(2) {width: 140px;}
            //&:nth-of-type(3) {}
            &:last-of-type   {width: 200px;}
          }
        }//.table_head
        &.table_body {
          cursor: pointer;

          &:hover {
            background-color: #FFDCC5!important;
            -webkit-transition: 0.2s ease-in-out;
            -moz-transition: 0.2s ease-in-out;
            -o-transition: 0.2s ease-in-out;
            transition: 0.2s ease-in-out;
          }

          .table_cell {
            font-size: 14px;
            padding: 10px 5px;

            >div {
              &.sp_table_head {
                display: none;
              }
              &.table_cell_content {}
            }

            .btn_wrap {
              >div {
                flex: 1;

                &:last-of-type {
                  margin-left: 10px;
                }

                button {
                  width: 100%;
                }
              }
            }

            position: relative;

            .innerArrow {
              display: inline-block;
              cursor: pointer;
              margin-right: 5px;
              padding: 4px 5px;
              vertical-align: middle;
              font-size: 12px;
              line-height: 1;
              margin-bottom: 3px;
            }
          }

          // 子情報
          &.isChild {
            background-color: #FDF0E5!important;
            animation-name: fade;
            animation-duration: 0.5s;
            animation-timing-function: ease-in;

            &:hover {
              background-color: #FFDCC5!important;
              -webkit-transition: 0.2s ease-in-out;
              -moz-transition: 0.2s ease-in-out;
              -o-transition: 0.2s ease-in-out;
              transition: 0.2s ease-in-out;
            }

            .table_cell {
              .statusCell {
                >span {
                  display: block;
                  text-align: center;
                  padding: 10px 0;
                  margin: 0 auto;
                  width: 90px;
                  border-radius: 5px;
                  color: #ffffff;

                  &.isPurchasing {background-color: #4eb9e5;}
                  &.contract     {background-color: #4e89c8;}
                  &.isContracted {background-color: #eaa75f;}
                  &.isApplication{background-color: #6bba53;}
                  &.isComplete   {background-color: #7a6eb0;}
                  &.isEndSale    {background-color: #8d8d8e;}
                  &.isSale       {background-color: #e94a49;}
                }
              }
            }

            @keyframes fade {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }
          }//&.isChild

        }//.table_body
      }//.table_row
    }//.table_wrap

    @media (max-width:  1199px) {
      .table_wrap .table_row {
        &.table_head .table_cell {
          font-size: 15px;
        }
        &.table_body.isChild .table_cell .statusCell > span {
          width: 75px;
        }
      }
    }
    @media (max-width:  991px) {
      padding: 0 20px;

      .table_wrap .table_row {
        &.table_head .table_cell {
          font-size: 12px;

          &:last-of-type {
            width: 100px;
          }
        }
        &.table_body {
          .table_cell {
            font-size: 12px;

            .btn_wrap {
              display: block;

              >div:last-of-type {
                margin-left: 0;
                margin-top: 10px;
              }
            }
          }
          &.isChild .table_cell .statusCell > span {
            width: 65px;
          }
        }
      }
    }
    @media (max-width:  767px) {
      width: calc(100% - 40px);
      overflow-x: scroll;
      padding: 0!important;
      margin: 0 auto;

      .table_wrap {
        .table_row {
          .table_cell {
            font-size: 14px!important;
          }
          &.table_body.isChild .table_cell .statusCell > span {
            margin: 0;
          }
        }

        /* 元のレスポンシブ用 */
        //display: block;
        //
        //.table_row {
        //  display: block;
        //
        //  &:nth-of-type(odd) {
        //    background-color: #ffffff!important;
        //  }
        //
        //  &:not(:first-of-type) {
        //    margin-top: 20px;
        //  }
        //
        //  .table_cell {
        //    display: block;
        //  }
        //
        //  &.table_head {
        //    display: none;
        //  }
        //  &.table_body .table_cell  {
        //    -js-display: flex; display: -webkit-flex; display: -ms-flexbox; display: -webkit-box; display: flex;
        //    padding: 0;
        //
        //    &:not(:last-of-type) {
        //      border-bottom: none;
        //    }
        //
        //    > div {
        //      &.sp_table_head {
        //        display: block;
        //        flex-basis: 8em;
        //        width: 8em;
        //        background-color: #ED6C00;
        //        color: #ffffff;
        //        font-weight: bold;
        //        padding: 10px 0;
        //      }
        //      &.table_cell_content {
        //        flex: 1;
        //        padding: 10px;
        //        border-left: 1px solid #E2E3E2;
        //        text-align: left;
        //      }
        //    }
        //    .btn_wrap {
        //      display: flex;
        //
        //      >div {
        //        flex: none;
        //        flex-basis: 120px;
        //        width: 120px;
        //
        //        &:last-of-type {
        //          margin-top: 0;
        //          margin-left: 10px;
        //        }
        //      }
        //    }
        //  }
        //}
      }
    }
    @media (max-width:  479px) {
      //.table_wrap .table_row {
      //  &.table_head .table_cell {
      //    font-size: 11px;
      //  }
      //  &.table_body .table_cell {
      //    font-size: 11px;
      //
      //    .btn_wrap {
      //      display: block;
      //
      //      >div {
      //        width: 100%;
      //
      //        &:last-of-type {
      //          margin-top: 10px;
      //          margin-left: 0;
      //        }
      //
      //        button {
      //          max-width: 120px;
      //        }
      //      }
      //    }
      //  }
      //}
    }
  }
}

//ページごとの項目の横幅 / align
.contentInner_wrap {

  //分譲マスタ
  &.table_masterSale {
    .tableArea.masterTableStyle .table_wrap {
      .table_row {
        &.table_body .table_cell {
          &:first-of-type  {text-align: center;}
          &:nth-of-type(6),
          &:nth-of-type(7)  {text-align: right;}
          &:last-of-type   {text-align: center;}
        }
      }
    }
  }

  //ユーザマスタ
  &.table_masterUser {
    .tableArea.masterTableStyle .table_wrap {
      @media (max-width:  767px) {
        width: 770px;
      }

      .table_row.table_head .table_cell {
        &:first-of-type  {width: 50px;}
        &:nth-of-type(2) {width: auto;}
        &:nth-of-type(3) {width: 120px;}
        &:last-of-type   {width: 110px;}

        @media (max-width:  991px) {
          &:first-of-type  {width: 40px;}
          &:last-of-type   {width: 90px;}
        }
        @media (max-width:  767px) {
          &:first-of-type  {width: 50px;}
          &:nth-of-type(2) {width: auto;}
          &:nth-of-type(3) {width: 160px;}
          &:last-of-type   {width: 130px;}
        }
      }
    }
  }

  //支店マスタ
  &.table_masterStore {
    .tableArea.masterTableStyle .table_wrap {
      @media (max-width:  767px) {
        width: 1000px;
      }

      .table_row {
        &.table_head .table_cell {
          &:first-of-type  {width: 50px;}
          &:nth-of-type(2) {width: 85px;}
          &:nth-of-type(3) {width: 90px;}
          &:nth-of-type(4) {width: 100px;}
          &:nth-of-type(5) {width: auto;}
          &:nth-of-type(6) {width: 125px;}
          &:nth-of-type(7) {width: 125px;}
          //&:nth-of-type(8) {width: 135px;}
          //&:last-of-type   {width: 100px;}

          @media (max-width:  1199px) {
            &:first-of-type  {width: 40px;}
            &:nth-of-type(2) {width: 80px;}
            &:nth-of-type(3) {width: 85px;}
            &:nth-of-type(4) {width: 85px;}
            &:nth-of-type(5) {width: auto;}
            &:nth-of-type(6) {width: 90px;}
            &:nth-of-type(7) {width: 90px;}
            //&:nth-of-type(8) {width: 110px;}
            //&:last-of-type   {width: 95px;}
          }
          @media (max-width:  991px) {
            &:first-of-type  {width: 35px;}
            &:nth-of-type(2) {width: 65px;}
            &:nth-of-type(3) {width: 65px;}
            &:nth-of-type(4) {width: 65px;}
            &:nth-of-type(5) {width: auto;}
            &:nth-of-type(6) {width: 60px;}
            &:nth-of-type(7) {width: 60px;}
            //&:nth-of-type(8) {width: 95px;}
            //&:last-of-type   {width: 75px;}
          }
          @media (max-width:  767px) {
            &:first-of-type  {width: 50px;}
            &:nth-of-type(2) {width: 85px;}
            &:nth-of-type(3) {width: 85px;}
            &:nth-of-type(4) {width: 85px;}
            &:nth-of-type(5) {width: auto;}
            &:nth-of-type(6) {width: 120px;}
            &:nth-of-type(7) {width: 120px;}
            //&:nth-of-type(8) {width: 150px;}
            //&:last-of-type   {width: 100px;}
          }
        }
        &.table_body .table_cell {
          &:first-of-type  {text-align: center;}
        }
      }
    }
  }

  //小・中学校マスタ
  &.table_masterSchool {
    .tableArea.masterTableStyle .table_wrap {
      @media (max-width:  767px) {
        width: 900px;
      }

      .table_row {
        &.table_head .table_cell {
          &:first-of-type  {width: 50px;}
          &:nth-of-type(2) {width: 150px;}
          &:nth-of-type(3) {width: 180px;}
          &:nth-of-type(4) {width: 120px;}
          &:nth-of-type(5) {width: 160px;}
          &:last-of-type   {width: auto;}

          @media (max-width:  1199px) {
            &:first-of-type  {width: 40px;}
            &:nth-of-type(2) {width: 120px;}
            &:nth-of-type(3) {width: 160px;}
            &:nth-of-type(4) {width: 110px;}
            &:nth-of-type(5) {width: 140px;}
            //&:last-of-type   {width: 90px;}
          }
          @media (max-width:  991px) {
            &:first-of-type  {width: 35px;}
            &:nth-of-type(2) {width: 95px;}
            &:nth-of-type(3) {width: 130px;}
            &:nth-of-type(4) {width: 100px;}
            &:nth-of-type(5) {width: 110px;}
            //&:last-of-type   {width: 75px;}
          }
          @media (max-width:  767px) {
            &:first-of-type  {width: 50px;}
            &:nth-of-type(2) {width: 140px;}
            &:nth-of-type(3) {width: 190px;}
            &:nth-of-type(4) {width: 110px;}
            &:nth-of-type(5) {width: 190px;}
            //&:last-of-type   {width: 100px;}
          }
        }
        &.table_body .table_cell {
          &:first-of-type  {text-align: center;}
        }
      }
    }
  }

  //市区町村マスタ
  &.table_masterCity {
    .tableArea.masterTableStyle .table_wrap {
      @media (max-width:  767px) {
        width: 100%;
        min-width: 550px;
      }

      .table_row {
        &.table_head .table_cell {
          &:first-of-type  {width: 50px;}
          &:nth-of-type(2) {width: auto;}
          &:nth-of-type(3) {width: 130px;}
          &:last-of-type   {width: 110px;}

          @media (max-width:  1199px) {
            &:first-of-type  {width: 40px;}
            &:nth-of-type(2) {width: auto;}
            &:nth-of-type(3) {width: 130px;}
            &:last-of-type   {width: 90px;}
          }
          @media (max-width:  991px) {
            &:first-of-type  {width: 35px;}
            &:nth-of-type(2) {width: auto;}
            &:nth-of-type(3) {width: 130px;}
            &:last-of-type   {width: 75px;}
          }
          @media (max-width:  767px) {
            &:first-of-type  {width: 50px;}
            &:nth-of-type(2) {width: auto;}
            &:nth-of-type(3) {width: 130px;}
            &:last-of-type   {width: 100px;}
          }
        }
        &.table_body .table_cell {
          &:first-of-type  {text-align: center;}
        }
      }
    }
  }

  //都道府県マスタ
  &.table_masterPrefecture {
    .tableArea.masterTableStyle .table_wrap {
      @media (max-width:  767px) {
        width: 100%;
        min-width: 450px;
      }

      .table_row {
        &.table_head .table_cell {
          &:first-of-type  {width: 50px;}
          //&:nth-of-type(2) {width: auto;}
          //&:last-of-type   {width: 110px;}
          &:last-of-type   {width: auto!important;}

          @media (max-width:  1199px) {
            &:first-of-type  {width: 40px;}
            //&:nth-of-type(2) {width: auto;}
            //&:last-of-type   {width: 90px;}
          }
          @media (max-width:  991px) {
            &:first-of-type  {width: 35px;}
            //&:nth-of-type(2) {width: auto;}
            //&:last-of-type   {width: 75px;}
          }
          @media (max-width:  767px) {
            &:first-of-type  {width: 50px;}
            //&:nth-of-type(2) {width: auto;}
            //&:last-of-type   {width: 100px;}
          }
        }
        &.table_body .table_cell {
          &:first-of-type  {text-align: center}
        }
      }
    }
  }

}
