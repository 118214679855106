.selectFile_sp {
  label {
    display: block;
    background-color: #ED6C00;
    font-size: 14px;
    line-height: 29px;
    width: 156px;
    max-width: 100%;
    height: 30px;
    padding: 0 20px;
    margin: 0;
    text-align: center;
    color: #ffffff;
    border: 1px solid #ED6C00;
    border-radius: 15px;
    box-sizing: border-box;

    &:hover {
      color: #ED6C00;
      background-color: #ffffff;
    }

    @media (max-width: 991px) {
    }
    @media (max-width: 479px) {
      font-size: 12px;
    }
    @media (max-width: 350px) {
    }

    input {
      display: none;
    }
  }
}