.header_wrap {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  border-bottom: 2px solid #ED6C00;
  background-color: #ffffff;
  .headerInner_wrap {
    padding: 15px 20px;

    .left_wrap {
      .logo_wrap {
        flex-basis: 200px;
        width: 200px;
      }
      .subTitle_wrap {
        font-size: 18px;
        line-height: 1;
        font-weight: bold;
        color: #401D00;
        margin-left: 20px;
      }
    }//.left_wrap

    .right_wrap {
      position: relative;

      .sp_menu_btn {
        display: none;
      }
      .menuInner_wrap {
        display: none;
      }
      .iconUser {
        flex-basis: 42px;
        width: 42px;
      }
      .userName {
        font-size: 18px;
        font-weight: bold;
        color: #401D00;
        margin-left: 10px;
      }
      .btn_wrap {
        flex-basis: 30px;
        width: 30px;

        &.btn_setting {
          margin-left: 20px;
        }
        &.btn_logout {
          margin-left: 10px;
        }
      }
    }//.right_wrap

  }//.headerInner_wrap
}//.header_wrap

//高さ確保用
.header_spacer {
  display: block;
  width: 100%;
  height: 64px;
}

@media (max-width: 991px) {
  .header_wrap .headerInner_wrap {
    .left_wrap {
      .logo_wrap {
        flex-basis: 160px;
        width: 160px;
      }
      .subTitle_wrap {
        font-size: 16px;
      }
    }
    .right_wrap {
      .userName {
        font-size: 16px;
      }
      .iconUser {
        flex-basis: 35px;
        width: 35px;
      }
    }
  }
  .header_spacer {
    height: 62px;
  }
}
@media (max-width: 767px) {
  .header_wrap .headerInner_wrap {
    display: block;
    padding: 0;

    .left_wrap {
      display: block;
      padding: 10px;
      border-bottom: 2px solid #ED6C00;
      &.hide_menu {
        border-bottom: none;
      }
      .logo_wrap {
        width: 100%;

        img {
          margin: 0 auto;
        }
      }
      .subTitle_wrap {
        margin-left: 0;
        text-align: center;
        margin-top: 10px;
      }
    }
    .right_wrap {
      padding: 5px 10px;

      .sp_menu_btn {
        display: block;
        flex: 1;

        button {
          display: flex;
          width: 30px;
          height: 30px;
        }
      }
      .menuInner_wrap {
        display: block;
        position: absolute;
        top: 42px;
        left: 0;
        width: 100%;
        background-color: #3F3F3F;

        .menuItem {
          padding: 5px 5px 5px 15px;
          background-color: #3F3F3F;
          border-bottom: 2px solid #252525;
          position: relative;

          &:active {
            background-color: #565655;
          }

          >div {
            &:first-of-type {
              flex-basis: 30px;
              width: 30px;
            }
            &:nth-of-type(2) {
              font-size: 14px;
              color: #ffffff;
              margin-left: 10px;
            }
          }
          .arrow_active {
            display: none;

            color: #ffffff;
            font-size: 14px;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
          }
          &.active {
            background-color: #565655;

            .arrow_active {
              display: block;
            }
          }
        }
      }
      .userName {
        font-size: 12px;
      }
    }
  }
  .header_spacer {
    height: 127px;
  }
}
@media (max-width: 350px) {
  .header_wrap .headerInner_wrap {
    .left_wrap {
      .logo_wrap img {
        height: 25px;
        width: auto;
      }
      .subTitle_wrap {
        font-size: 14px;
      }
    }
    .right_wrap {
      .menuInner_wrap {
        top: 42px;

        .menuItem {
          padding: 7px;

          > div {
            &:first-of-type {
              flex-basis: 23px;
              width: 23px;
            }
            &:last-of-type {
              font-size: 14px;
            }
          }
        }
      }
      .iconUser {
        flex-basis: 30px;
        width: 30px;
      }
      .userName {
        font-size: 10px;
      }
      .btn_wrap {
        flex-basis: 25px;
        width: 25px;
      }
    }
  }
  .header_spacer {
    height: 113px;
  }
}
