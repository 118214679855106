.content_wrap {
  h3 {
    text-align: center;
  }
  &.cont_article_detail {

    button {
      &.btn_toReservation {
        font-size: 20px;
        width: auto;
        height: 40px;
        line-height: 38px;
        border-radius: 20px;
        padding: 0 20px;
        &.disable{
          pointer-events: none;
          opacity: .4;
        }
        @media (max-width: 768px) {
          font-size: 18px;
          height: 35px;
          line-height: 33px;
          border-radius: 18px;
        }
        @media (max-width: 479px) {
          font-size: 12px;
          height: 30px;
          line-height: 28px;
          border-radius: 15px;
        }
      }
    }

    //画像・PDFファイル選択/削除用ボタンスタイル定義
    %btnStyle_addIMage {
      font-size: 14px;
      font-weight: bold;
      max-width: 100%;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      padding: 5px 0;
      text-align: center;

      &:hover {
        background-color: #ffffff!important;
      }
      @media (max-width: 479px) {
        font-size: 12px;
      }
    }
    %btnColor_selectImageBtn {
      color: #EE7925;
      border: 2px solid #F9C681;
      background-color: #FFE7C4;
    }
    %btnColor_selectedImageDeleteBtn {
      color: #737373;
      border: 2px solid #C2C2C2;
      background-color: #E7E7E7;
    }
    //画像・PDFファイル選択/削除用ボタンスタイル定義 end

    .status_wrap {
      >.flex_box {
        border: 2px solid #3F3F3F;
        background-color: #E2E3E2;
        line-height: 1.2;
        color: #3F3F3F;
        padding: 7px 10px 5px;

        &.reserved {
          border: 2px solid #401D00;
          background-color: #401D00;
          color: #ffffff;
        }

        >div {
          &:last-of-type {
            font-size: 19px;
            font-weight: bold;
            margin-left: 10px;
          }
        }
      }
    }
    .btnList_wrap {
      >div {
        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }
    }
    .tableArea {
      .tableInner_wrap {
        border: 1px solid #E2E3E2;

        >.flex_box {
          &:not(:first-of-type) {
            border-top: 1px solid #E2E3E2;
          }
          &.salesSituation {
            >div {
              &:last-of-type {
                height: 150px;
                overflow-y: auto;

                >div {
                  border-bottom: 1px solid #FFD9B9;
                  padding-bottom: 2px;
                  margin-bottom: 5px;

                  &.addInfoInput {
                    border-bottom: none;
                    padding-bottom: 0;
                    margin-bottom: 0;
                  }
                }
              }
              select {
                min-width: 1px;
              }
            }
          }
          >div {
            font-size: 16px;
            padding: 10px;
            word-break: break-all;

            &:first-of-type {
              flex-basis: 150px;
              width: 150px;
              background-color: #ED6C00;
              color: #ffffff;
            }
            &:nth-of-type(2) {
              flex: 1;
            }
            &:nth-of-type(3) {
              flex-basis: 150px;
              width: 150px;
              background-color: #ED6C00;
              color: #ffffff;
            }
            &:last-of-type {
              flex: 1;
            }
            &:not(:first-of-type) {
              border-left: 1px solid #E2E3E2;
            }
            &.withButton_wrap {
              >div {
                &:first-of-type {
                  //flex: 1;
                  select {
                    min-width: 9em;
                  }
                }
                &:last-of-type {
                  flex-basis: 34px;
                  width: 34px;
                  margin-left: 10px;

                  button {
                    width: 100%;
                    height: auto;
                    padding: 5px;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;

                    img {
                      width: 22px;

                      &.iconSearch   {display: block;}
                      &.iconSearchOn {display: none;}
                    }
                    &:hover {
                      img {
                        &.iconSearch   {display: none;}
                        &.iconSearchOn {display: block;}
                      }
                    }
                  }
                }
              }
            }

            //種別
            &.item_type {
              >.flex_box {
                >div {
                  word-break: keep-all;

                  &:first-of-type {
                    flex: 1;
                  }
                  &:last-of-type {
                    flex-basis: 220px;
                    width: 220px;
                  }

                  .minBR {
                    display: none;
                  }
                }
              }

              @media (max-width: 1200px) {
                >.flex_box > div:last-of-type {
                  flex-basis: 120px;
                  width: 120px;

                  .minBR {
                    display: block;
                    margin-top: 5px;
                  }
                }
              }
              @media (max-width: 991px) {
                >.flex_box > div:last-of-type {
                  flex-basis: 105px;
                  width: 105px;
                }
              }
            }
            /////////////////////////////////////////PDF選択、PDF追加用
            .pdf_wrap {
              button {
                @extend %btnStyle_addIMage;
                @extend %btnColor_selectImageBtn;
                display: flex;
                width: 130px;
                margin: 0 auto;
              }
            }
            /////////////////////////////////////////PDF選択、PDF追加用 end
            /////////////////////////////////////////画像表示用
            &.displayImage_wrap{
              height: 200px;

              >img {
                width: auto;
                max-height: 100%;
                margin: 0 auto;
              }
            }
            /////////////////////////////////////////画像表示用 end
          }//>div
        }//>.flex_box
      }//.tableInner_wrap
    }//.tableArea

    @media (max-width: 1199px) {
      .status_wrap > div {
        font-size: 22px;
      }
      .btnArea {
        display: block;

        .status_wrap {
          display: inline-block;
          margin-bottom: 10px;
        }
      }
      .tableArea .tableInner_wrap > .flex_box > div.withButton_wrap > div {
        &:last-of-type {
          flex-basis: 28px;
          width: 28px;
          margin-left: 5px;

          button {
            padding: 4px;
          }
        }
      }
    }
    @media (max-width: 991px) {
      .status_wrap > .flex_box {
        >div {
          &:last-of-type {
            font-size: 16px;
          }
        }
      }
      .btnArea {
        .btnList_wrap {
          -webkit-box-lines:multiple; -webkit-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-wrap: wrap;

          >div {
            margin-bottom: 10px;

            button {
              width: 120px;
            }
          }
        }
      }
      .tableArea .tableInner_wrap > .flex_box {
        > div {
          font-size: 14px;

          &:first-of-type,
          &:nth-of-type(3) {
            flex-basis: 105px;
            width: 105px;
          }
          &:first-of-type {
            select {
              width: 100%;
              padding: 5px 25px 5px 5px;
            }
          }
          &.withButton_wrap > div {
            &:first-of-type {
              select {
                max-width: 7em;
                min-width: 6em;
              }
            }
            &:last-of-type {
              flex-basis: 24px;
              width: 24px;

              button {
                padding: 4px;
              }
            }
          }
          .sp_block {
            &.input_3_col > div {
              &.basis_10rem {
                flex-basis: 8rem;
                width: 8rem;
              }
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      .status_wrap > div {
        font-size: 18px;
      }
      .tableArea .tableInner_wrap > .flex_box {
        &.sp_flex_container {
          -webkit-box-lines:multiple; -webkit-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-wrap: wrap;
        }
        &:not(:first-of-type) {
          border: none;

          >div {
            border-top: 1px solid #E2E3E2;
          }
        }
        > div {
          &:first-of-type {flex-basis: 35%;}
          &:nth-of-type(2) {flex-basis: 60%;}
          &:nth-of-type(3) {flex-basis: 35%;}
          &:last-of-type {flex-basis: 60%;}
          &:not(:first-of-type) {border-left: none;}

          &:first-of-type {
            select {
              width: 135%;
              transform: scale(0.7);
              -moz-transform-origin: 0px 0px;
              -webkit-transform-origin: 0px 0px;
              -o-transform-origin: 0px 0px;
              -ms-transform-origin: 0px 0px;
              transform-origin: 0px 0px;
            }
          }
          &.withButton_wrap > div {
            &:first-of-type {
              flex: none;
            }
          }
        }
      }
    }
    @media (max-width: 479px) {
      .status_wrap > .flex_box {
        >div {
          &:first-of-type {
            //flex-basis: 25px;
            img {
              height: 21px;
              width: auto;
            }
          }
          &:last-of-type {
            font-size: 14px;
          }
        }
      }
      .btnList_wrap {
        -webkit-box-lines:multiple; -webkit-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-wrap: wrap;

        > div {
          flex-basis: 50%;
          padding: 0 5px;

          &:not(:last-of-type) {
            margin-right: 0;
          }
          &:nth-of-type(3),
          &:nth-of-type(4) {
            //margin-top: 10px;
          }

          button {
            margin: 0 auto;
          }
        }
      }
      .tableArea .tableInner_wrap > .flex_box {
        > div {
          font-size: 10px;
        }
      }
    }
    @media (max-width: 350px) {}


    /**
     * 編集モード
     */
    &.mode_edit {
      .tableArea .tableInner_wrap > .flex_box {
        > div {
          input,
          select {
            &.max_w_8 {max-width: 8rem;}
          }
          &.padd_t_adjust {
            padding-top: 20px;
          }
          &.displayImage_wrap {
            height: auto;
            display: block;
          }

          //最寄り駅用幅調整用クラス
          .sp_block {
            .margin_l_20 {
              margin-left: 20px;
            }
            .basis_10rem {
              flex-basis: 10rem;
              width: 10rem;
              max-width: 100%;
            }
            //交通
            &.input_3_col {
              >div {
                &:last-of-type {
                  input {
                    flex-basis: calc(100% - 4rem);
                    width: calc(100% - 4rem);
                  }
                }
              }
            }
          }

          /////////////////////////////////////////画像再選択、画像追加用
          .editImage_wrap {
            height: 200px;

            .editImageInne_wrap {
              height: calc(100% - 45px);
            }
            img {
              max-height: 100%;
            }
            .selectImageBtn_wrap {
              flex: 1;

              &:not(:first-of-type) {
                margin-left: 10px;
              }
              button {
                @extend %btnStyle_addIMage;
                display: inline-block;
                width: 120px;
                &.selectImageBtn {
                  @extend %btnColor_selectImageBtn;
                }
                &.selectedImageDeleteBtn {
                  @extend %btnColor_selectedImageDeleteBtn;
                }
              }
            }

            //pdf変更の場合
            &.editPdf {
              height: 100%;

              .editPdfInner {
                height: calc(100% - 45px);
                // min-height: 200px;
                width: 250px;
                margin: 0 auto;
                padding: 10px 5px;
                border: 2px solid #EE7925;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
              }
            }
          }
          .addFile_wrap {
            height: 100%;

            .addFileArea_wrap {
              height: 100%;
              min-height: 200px;
              border: 2px dotted #EE7925;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              border-radius: 5px;

              button {
                @extend %btnStyle_addIMage;
                width: 150px;
                color: #EE7925;
                border: 2px solid #F9C681;
                background-color: #FFE7C4;
              }
            }
          }
          /////////////////////////////////////////画像再選択、画像追加用 end
        }
        &.salesSituation {
          >div {
            &:last-of-type {
              //info追加
              .addInfoInput {
                //border-top: 1px solid #ED6C00;
                margin-top: 30px;
                //padding-top: 10px;

                >div {
                  &:first-of-type {
                    flex: 1;
                  }
                  &:last-of-type {
                    flex-basis: 60px;
                    width: 60px;
                    margin-left: 10px;

                    button {
                      width: auto;
                      padding: 0 15px;
                    }
                  }
                }
              }//addInfoInput

              >div {
                border-bottom: none;

                &.infoText {
                  font-size: 14px;
                  padding: 10px 5px;
                  border-radius: 5px;
                  box-shadow: none;
                  border: 1px solid #E2E3E2;
                  background-color: #EEEEEE;
                  box-sizing: border-box;
                  color: #999999;
                }
              }
            }//&:last-of-type
          }//>div
        }//&.salesSituation
      }
      @media (max-width: 1199px) {
        .tableArea .tableInner_wrap > .flex_box {
          >div {
            //最寄り駅用幅調整用クラス
            .editImage_wrap {
              .selectImageBtn_wrap button {
                width: 100%;
              }
              //pdf変更の場合
              &.editPdf {
                .editPdfInner {
                  width: 100%;
                }
              }
            }
            //画像再選択、画像追加用
            .addFile_wrap .addFileArea_wrap {
              font-size: 12px;
            }
          }
        }
      }
      @media (max-width: 991px) {
        .tableArea .tableInner_wrap > .flex_box {
          >div {
            //画像再選択、画像追加用
            .addFile_wrap .addFileArea_wrap {
              font-size: 10px;

              button {
                width: 130px;
              }
            }
          }
        }
      }
      @media (max-width: 767px) {
        .tableArea .tableInner_wrap > .flex_box {
          >div {
            //最寄り駅用幅調整用クラス
            .sp_block {
              .margin_l_20 {
                margin-left: 10px;
              }
              .basis_10rem {
                flex: 1;
                width: auto;
                max-width: 100%;
              }
              &.input_3_col {
                display: block;

                .margin_l_20 {
                  margin-left: 0;
                  margin-top: 10px;
                }
              }
            }
            //画像再選択、画像追加用
            .addFile_wrap .addFileArea_wrap {
              font-size: 14px;
              padding: 10px 0;
            }
          }
        }
      }
      @media (max-width: 479px) {
        .tableArea .tableInner_wrap > .flex_box {
          &.salesSituation > div:last-of-type .addInfoInput > div:last-of-type {
            flex-basis: 32px;
            width: 32px;

            button {
              font-size: 10px;
              padding: 0 5px;
              border-radius: 10px;
            }
          }
          > div {
            input.max_w_8 {
              max-width: none;
            }
            //最寄り駅用幅調整用クラス
            .sp_block {
              display: block;

              .margin_l_20 {
                margin-left: 0;
                margin-top: 10px;
              }
            }
            //画像再選択、画像追加用
            .editImage_wrap .selectImageBtn_wrap button {
              width: 100%;
            }
            .addFile_wrap .addFileArea_wrap {
              font-size: 10px;

              button {
                max-width: 100%;
              }
            }
          }
        }
      }
    }

  }//.cont_article_detail
}//.content_wrap
