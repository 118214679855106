$tb: 1199px; //タブレット
$sp: 767px; //スマホ

@mixin mq-tb {
  @media (max-width: ($tb)) {
    @content;
  }
}

@mixin mq-sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

@mixin flex {
  -js-display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
}

.content_wrap {
  &.masterSale_cont {
    .searchArea {
      &.masterSale {
        .select_wrap {
          @media (max-width: 479px) {
            display: block;
          }

          > div.right_wrap {
            margin-left: 40px;
            @media (max-width: 479px) {
              margin-top: 10px;
              margin-left: 0;
            }
          }

          > div {
            > div {
              &:last-of-type {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }

    .tableArea {
      &.masterTableStyle {
        .table_wrap {
          @include mq-sp {
            display: block;
            width: auto;
          }

          .table_row {
            @include mq-sp {
              display: block;
            }

            &:not(:first-of-type) {
              @include mq-sp {
                margin-top: 20px;
              }
            }

            &.table_head {
              @include mq-sp {
                display: none;
              }

              .table_cell {
                width: 100px;

                &:first-of-type {
                  width: 5%;
                }

                &:nth-of-type(2) {
                  width: 20%;
                }

                &:nth-of-type(3),
                &:nth-of-type(4),
                &:nth-of-type(6),
                &:nth-of-type(7) {
                  width: 10%;
                }

                &:nth-of-type(5) {
                  width: auto;
                }
                &:nth-of-type(8) {
                  width: 135px;

                  @media (max-width: 1199px) {width: 105px;}
                  @media (max-width: 991px) {width: 80px;}
                }
              }
            }

            &.table_body {
              .table_cell {
                @include mq-sp {
                  @include flex;
                  padding: 0;
                }

                > div.sp_table_head {
                  @include mq-sp {
                    display: block;
                    flex-basis: 150px;
                    width: 150px;
                    background-color: #ED6C00;
                    color: #ffffff;
                    font-size: 14px;
                    font-weight: bold;
                    padding: 10px;
                    text-align: left;
                  }
                }

                > div.table_cell_content {
                  @include mq-sp {
                    flex: 1;
                    font-size: 14px;
                    padding: 10px;
                    border-left: 1px solid #E2E3E2;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
