.errorPage_wrap {
  height: 100%;

  @media (max-width: 767px) {
    // height: 100vh;
    padding-top: 100px;
    display: block!important;

    .errorPageInner_wrap {
      text-align: center;

      button {
        margin: 0 auto;
      }
    }
  }
}