html {
  font-family: '小塚ゴシック Pr6N', 'メイリオ', 'Meiryo', 'Hiragino Sans',
    'ヒラギノ角ゴ Pro W4', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3',
    sans-serif !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  html {
    overflow: auto;
  }
}
body {
  width: 100%;
  height: 100%;
  font-size: 18px !important;
  margin: 0 auto;
  padding: 0;
  color: #767676;
  line-height: 1.4;
  word-wrap: break-word;
}

@media screen and (max-width: 1199px) {
  body {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 991px) {
  body {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 767px) {
  html {
    height: auto;
  }
  body {
    font-size: 12px !important;
    height: auto;
  }
}
//@media screen and (max-width: 479px)  { bodyList { font-size: 10px !important; }}

/************************************************************************
                                                                         common
 ************************************************************************/
h1,
h2,
h3,
h4,
h5,
p,
a,
li {
  margin: 0;
  padding: 0;
  line-height: 1.4;
  font-weight: normal !important;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: #231815;
  &:hover,
  &:focus {
    text-decoration: none !important;
  }
  &:hover {
    cursor: pointer;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  &.textLink {
    text-decoration: underline;
  }
}

input,
textarea {
  font-family: '小塚ゴシック Pr6N', 'メイリオ', 'Meiryo', 'Hiragino Sans',
    'ヒラギノ角ゴ Pro W4', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3',
    sans-serif !important;
  font-family: 'メイリオ', 'meiryo', 'Hiragino Kaku Gothic Pro',
    'ヒラギノ角ゴ Pro W3', ​sans-serif!important\9;
  &.textInput {
    font-size: 14px;
    padding: 10px 5px;
    margin: 0;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #e2e3e2;
    background-color: #ffffff;
    box-sizing: border-box;
    width: 100%;
    display: block;
    color: #767676;

    @media screen and (max-width: 991px) {
      font-size: 12px;
    }
    @media screen and (max-width: 767px) {
      padding: 5px;
    }
    @media screen and (max-width: 479px) {
      font-size: 16px;
    }
  }
}

select {
  color: #767676;
  border: 1px solid #e2e3e2;
  border-radius: 5px;
  box-shadow: none;
  font-size: 16px;
  min-width: 10em;
  min-height: 46px;
  max-width: 100%;
  padding: 10px 18px 10px 5px;
  background-image: url('../src/img/common/icon_selectArrow.png');
  background-repeat: no-repeat;
  background-size: 8px;
  background-position: right 5px center;
  background-color: #ffffff;

  @media screen and (max-width: 991px) {
    min-width: 8em;
  }

  @media screen and (max-width: 767px) {
    padding: 5px 18px 5px 5px;
    min-height: 36px;
    min-width: 1px;
    // font-size: 12px;
    // 拡大防止処理
    // font-size: 16px;
    // transform: scale(0.7);
    // -moz-transform-origin: 0px 0px;
    // -webkit-transform-origin: 0px 0px;
    // -o-transform-origin: 0px 0px;
    // -ms-transform-origin: 0px 0px;
    // transform-origin: 0px 0px;
  }
}

/* iOSでのデフォルトスタイルをリセット */
input[type='submit'],
input[type='button'] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}
input[type='submit']::-webkit-search-decoration,
input[type='button']::-webkit-search-decoration {
  display: none;
}
input[type='submit']:focus,
input[type='button']:focus {
  outline-offset: -2px;
}
input[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='date']::-webkit-clear-button {
  -webkit-appearance: none;
}
button {
  cursor: pointer;
  color: #767676;

  &.reset {
    display: block;
    background: none;
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
}
input {
  &.reset {
    display: block;
    background: none;
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
}
img {
  display: block;
  width: auto;
  max-width: 100%;
}

.mouse_hover:hover {
  opacity: 0.8;
  filter: alpha(opacity = 80);
  -ms-filter: 'alpha(opacity=80)';
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;

  img {
    opacity: 0.8;
    filter: alpha(opacity = 80);
    -ms-filter: 'alpha(opacity=80)';
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
}

a,
input,
button {
  &.btnStyle_1 {
    display: block;
    background-color: #ed6c00;
    font-size: 14px;
    line-height: 29px;
    width: 120px;
    max-width: 100%;
    height: 30px;
    padding: 0;
    margin: 0;
    text-align: center;
    color: #ffffff;
    border: 1px solid #ed6c00;
    border-radius: 15px;
    box-sizing: border-box;

    &:hover {
      color: #ed6c00;
      background-color: #ffffff;
    }

    @media (max-width: 991px) {
      width: 100px;
    }
    @media (max-width: 479px) {
      font-size: 12px;
      width: 90px;
    }
    @media (max-width: 350px) {
      width: 100px;
    }

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
      cursor: default;

      &:hover {
        color: #ffffff;
        background-color: #ed6c00;
      }
    }
  }

  &:hover {
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
}

* {
  box-sizing: border-box;
  -webkit-appearance: none;
}
*:focus {
  outline: none;
}
.cursor_p {
  cursor: pointer;
}
.word_keep {
  word-break: keep-all;
}
.padd_0 {
  padding: 0 !important;
}
.marg_0 {
  margin: 0 !important;
}
.marg_auto {
  margin: 0 auto !important;
}
.font_b {
  font-weight: bold;
}
.line_h_1 {
  line-height: 1;
}
.display_b {
  display: block;
}
.display_inb {
  display: inline-block;
}
.align_c {
  text-align: center;
}
.align_r {
  text-align: right;
}
.align_l {
  text-align: left;
}
.position_r {
  position: relative;
}
.c_333 {
  color: #333333;
}
.c_666 {
  color: #666666;
}
.c_999 {
  color: #999999;
}
.c_red {
  color: red;
}
.divide5 {
  display: block;
  width: 100%;
  height: 5px;
}
.divide10 {
  display: block;
  width: 100%;
  height: 10px;
}
.divide15 {
  display: block;
  width: 100%;
  height: 15px;
}
.divide20 {
  display: block;
  width: 100%;
  height: 20px;
}
.divide25 {
  display: block;
  width: 100%;
  height: 25px;
}
.divide30 {
  display: block;
  width: 100%;
  height: 30px;
}
.divide40 {
  display: block;
  width: 100%;
  height: 40px;
}
.divide50 {
  display: block;
  width: 100%;
  height: 50px;
}
.divide60 {
  display: block;
  width: 100%;
  height: 60px;
}
.error_wrap {
  font-size: 14px;
  color: red;
  margin-top: 5px;
  display: block;
  @media (max-width: 767px) {
    font-size: 12px;
  }
  @media (max-width: 479px) {
    font-size: 10px;
  }
}
.selectDisabled {
  //テキスト選択無効
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &.style_disc {
    list-style-type: disc;
    padding-left: 2.5rem;
  }

  &.style_decimal {
    list-style-type: decimal;
    padding-left: 2.5rem;
  }

  &.style_lower_alpha {
    list-style-type: lower-alpha;
    padding-left: 2.5rem;
  }
}

.flex_box {
  -js-display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flex_align_start {
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.flex_align_stretch {
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.flex_align_center {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex_align_end {
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.flex_space_between {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify;
}
.flex_content_center {
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}
.flex_content_end {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: end;
}
.flex_content_around {
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: distribute;
}
.flex_container_wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}

.pc {
  display: block !important;
}
.sp,
.sma_sp {
  display: none !important;
}

@media (max-width: 767px) {
  .pc {
    display: none !important;
  }
  .sp {
    display: block !important;
  }
}
@media (max-width: 479px) {
  .sma_sp {
    display: block !important;
  }
}

/* IE11 */
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  html,
  input,
  textarea {
    font-family: 'メイリオ', 'meiryo', 'Hiragino Kaku Gothic Pro',
      'ヒラギノ角ゴ Pro W3', ​sans-serif !important;
  }
}
/* IE10対応 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html,
  input,
  textarea {
    font-family: 'メイリオ', 'meiryo', 'Hiragino Kaku Gothic Pro',
      'ヒラギノ角ゴ Pro W3', ​sans-serif !important;
  }
  img {
    border: none;
  }
}

/**
 * 表示エリア高さ設定
 */
#root {
  height: 100%;

  .App {
    height: 100%;
    position: relative;

    //モーダル表示時
    &.fixed {
      position: fixed;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 767px) {
    height: auto;

    .App {
      height: auto;
    }
  }
}

/**
 * table
 */
.table_wrap {
  display: table;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  .table_row {
    display: table-row;

    .table_cell {
      display: table-cell;
      vertical-align: middle;
    }
  }
}

/**
 * debugエリア
 */
.debug_wrap {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: #ffffff;
  border: 1px solid #999999;
  border-radius: 5px;
  padding: 10px;
}

/**
 * data-pickerカスタマイズ
 */
.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input {
      cursor: pointer;
      font-size: 14px;
      padding: 10px;
      margin: 0;
      border-radius: 5px;
      box-shadow: none;
      border: 1px solid #e2e3e2;
      background-color: #ffffff;
      box-sizing: border-box;
      width: 100%;
      display: block;
      color: #767676;

      background-image: url('./img/common/icon_calender.png');
      background-repeat: no-repeat;
      background-position: right 5px center;
      background-size: 18px;

      @media screen and (max-width: 991px) {
        font-size: 12px;
      }
      @media screen and (max-width: 767px) {
        padding: 5px;
      }
      @media screen and (max-width: 479px) {
        font-size: 10px;
      }
    }
    button {
      &.react-datepicker__close-icon {
        width: 28px;
        height: calc(100% - 2px);
        top: 1px;
        right: 1px;
        background-color: #ffffff;
        border-radius: 5px;

        &::after {
          position: absolute;
          line-height: 16px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin: 0;
          background-color: #ed6c00;
        }
      }
    }
  }
}
.react-datepicker {
  border: 1px solid #e2e3e2;
}
.react-datepicker-popper {
  z-index: 6!important;
}
.react-datepicker__month-container {
  .react-datepicker__header {
    background-color: #ed6c00;
    border-bottom: none;

    .react-datepicker__current-month {
      color: #ffffff;
    }
    .react-datepicker__day-name {
      color: #ffffff;
    }
  }
  .react-datepicker__day--today {
    background-color: #ed6c00;
    color: #ffffff;
  }
}
.react-datepicker__navigation--next {
  border-left-color: #ffffff;
}
.react-datepicker__navigation--previous {
  border-right-color: #ffffff;
}

//スマホ・タブレットで開いた時はこっち
.sp_dataPicker_wrap {
  > div {
    &:first-of-type {
      flex-basis: 125px;
      width: 125px;
    }
    &:last-of-type {
      flex-basis: 20px;
      width: 20px;
      margin-left: 5px;
    }
    input {
      min-height: 45px;
    }
  }
  button {
    background-color: #ed6c00;
    color: #ffffff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
  }

  @media (max-width: 991px) {
    > div {
      &:first-of-type {
        flex-basis: 130px;
        width: 130px;
      }
      input {
        min-height: 40px;
      }
    }
  }
  @media (max-width: 767px) {
    > div {
      input {
        min-height: 30px;
      }
    }
  }
}

/**
 * 基本構造
 */
.body_wrap {
  .menu_wrap {
    flex-basis: 240px;
    width: 240px;
    height: calc(100vh - 64px);
    overflow: auto;
    background-color: #3f3f3f;
  }
  .content_wrap {
    flex: 1;
    height: calc(100vh - 64px);
    overflow: auto;

    .contentInner_wrap {
      padding: 20px 0;

      .contentHead_wrap {
        padding: 0 30px;

        .contentHeadInner_wrap {
          border-bottom: 2px solid #e2e3e2;
          padding-bottom: 10px;

          .contentHead_text {
            font-weight: bold;
            color: #ed6c00;

            > span {
              &:first-of-type {
                font-size: 24px;
              }
              &.innerBar {
                width: 10px;
                border-bottom: 2px solid #ed6c00;
                margin: 0 15px;
              }
              &:last-of-type {
                font-size: 18px;
              }
            }
          }
        }
      }

      .searchArea {
        background-color: #f6f7f6;
        //margin: 30px 0;
        //padding: 30px;
        margin: 10px 0;
        padding: 10px 30px;

        div {
          font-size: 14px;
        }

        //マスタ管理系で使用
        .masterInput_wrap {
          .left_wrap {
            flex-basis: 380px;
            width: 380px;

            > div {
              &:last-of-type {
                flex: 1;
                margin-left: 10px;
              }
            }
          }
          .right_wrap {
            flex: 1;
            margin-left: 40px;
          }
        }

        .checkbox_wrap {
          > div {
            &:last-of-type {
              margin-left: 10px;
            }
          }
        }
      }

      .btnArea {
        padding: 0 30px;
        margin: 30px 0 10px;
      }

      .tableArea {
        // padding: 0 30px;
      } //.tableArea
    } //.contentInner_wrap

    &.cont_article_detail {
      .contentInner_wrap {
        .tableArea {
          padding: 0 30px;
        }
      }
    }

    &.masterSystem_cont .contentInner_wrap .pager_wrap {
      padding: 0 30px;
      // margin-top: 10px;
      text-align: right;

      ul {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
      }
    }

    //マスタ管理系の新規追加ボタン
    //.btn_newAdd_wrap {
    //  button {
    //    font-size: 20px;
    //    height: 40px;
    //    line-height: 41px;
    //    border-radius: 20px;
    //    width: 150px;
    //  }
    //}
  } //.content_wrap

  @media (max-width: 1199px) {
    .menu_wrap {
      flex-basis: 200px;
      width: 200px;
    }
    .content_wrap
      .contentInner_wrap
      .contentHead_wrap
      .contentHeadInner_wrap
      .contentHead_text {
      > span {
        &:first-of-type {
          font-size: 22px;
        }
        &:last-of-type {
          font-size: 17px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .menu_wrap {
      flex-basis: 160px;
      width: 160px;
      height: calc(100vh - 62px);
    }
    .content_wrap {
      height: calc(100vh - 62px);

      .contentInner_wrap {
        padding: 20px 0;

        .contentHead_wrap {
          padding: 0 20px;

          .contentHeadInner_wrap .contentHead_text {
            > span {
              &:first-of-type {
                font-size: 18px;
              }
              &:last-of-type {
                font-size: 14px;
              }
            }
          }
        }
        .searchArea {
          div {
            font-size: 12px;
          }
          .masterInput_wrap .left_wrap {
            flex-basis: 330px;
            width: 330px;
          }
        }
        //.tableArea,
        .btnArea {
          padding: 0 20px;
        }
      }
      &.cont_article_detail {
        .contentInner_wrap {
          .tableArea {
            padding: 0 20px;
          }
        }
      }
      &.masterSystem_cont .contentInner_wrap .pager_wrap {
        padding: 0 20px;
      }
      //.btn_newAdd_wrap {
      //  button {
      //    font-size: 16px;
      //    height: 34px;
      //    line-height: 35px;
      //    border-radius: 17px;
      //    width: 130px;
      //  }
      //}
    }
  }
  @media (max-width: 767px) {
    .content_wrap {
      height: auto;
      overflow: visible;
      width: 100%;

      .contentInner_wrap .searchArea .masterInput_wrap {
        .left_wrap {
          flex-basis: 240px;
          width: 240px;
        }
        .right_wrap {
          margin-left: 20px;
        }
      }
      //.btn_newAdd_wrap {
      //  button {
      //    font-size: 14px;
      //    height: 30px;
      //    line-height: 31px;
      //    border-radius: 15px;
      //    width: 120px;
      //  }
      //}
    }
  }
  @media (max-width: 479px) {
    .content_wrap {
      .contentInner_wrap {
        .contentHead_wrap .contentHeadInner_wrap .contentHead_text {
          > span {
            &:first-of-type {
              font-size: 15px;
            }
            &.innerBar {
              width: 7px;
              border-bottom: 1px solid #ed6c00;
              margin: 0 10px;
            }
            &:last-of-type {
              font-size: 12px;
            }
          }
        }
        .searchArea {
          padding: 20px 10px;

          .masterInput_wrap {
            display: block;

            .left_wrap {
              width: 100%;
            }
            .right_wrap {
              margin-left: 0;
              margin-top: 10px;
            }
          }
        }
        .sort_pager_wrap {
          -webkit-box-lines: multiple;
          -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
          -ms-flex-wrap: wrap;

          .sort_pager_inner_wrap {
            order: 2;
            flex-basis: 100%;
          }
        }
        .btn_newAdd_wrap {
          flex-basis: 100%;
          order: 1;
          margin-bottom: 10px;

          button {
            margin: 0 auto;
          }
        }
      }
      &.masterSystem_cont .contentInner_wrap .pager_wrap {
        text-align: center;
      }
    }
  }
  @media (max-width: 350px) {
  }
}

/**
 * sort,pager
 * article-listの特殊ページャ含む
 */
.sort_pager_wrap {
  padding: 0 30px;

  .sort_pager_inner_wrap {
    font-size: 14px !important;

    .select_wrap {
      flex: 1;
      margin-right: 20px;

      > div {
        &:not(:first-of-type) {
          margin-left: 10px;
        }
      }
    } //.select_wrap
    .btn_wrap {
      flex-basis: 120px;
      width: 120px;

      .sp_pagenum_wrap {
        display: none;
      }
    }
    .pagenum_wrap {
      .pagenumInner_wrap {
        > div {
          &:nth-of-type(2) {
            margin: 0 10px;
          }
        }
      }
    } //.pagenum_wrap
  } //.sort_pager_inner_wrap

  @media (max-width: 991px) {
    padding: 0 20px;

    .sort_pager_inner_wrap .btn_wrap {
      flex-basis: 100px;
      width: 100px;
    }
  }
  @media (max-width: 767px) {
    .sort_pager_inner_wrap {
      font-size: 12px !important;
    }
  }
  @media (max-width: 479px) {
    .sort_pager_inner_wrap {
      display: block;

      .select_wrap {
        margin-right: 0;
        -webkit-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        -webkit-box-pack: center;

        > div {
          flex: auto;

          select {
            width: 100%;
          }
        }
      }
      .btn_wrap {
        width: 100%;
        margin-top: 10px;

        .sp_pagenum_wrap {
          display: flex;
          flex: 1;

          > div {
            &:nth-of-type(2) {
              margin: 0 5px;
            }
          }
        }

        button {
          flex-basis: 100px;
          width: 100%;
        }
      }
      .pagenum_wrap {
        display: none;
      }
    }
  }
} //.sort_pager_wrap

.displayNum_pager_wrap {
  padding: 0 30px;
  font-size: 14px !important;

  .pager_wrap {
    padding: 0 !important;
    margin-top: 0;
  }

  @media (max-width: 991px) {
    padding: 0 20px;
    font-size: 12px !important;
  }
  @media (max-width: 479px) {
    display: block;

    .pagenum_wrap {
      .pagenumInner_wrap {
        -webkit-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        -webkit-box-pack: center;
      }
    }
    .pager_wrap {
      margin-top: 10px;
    }
  }
} //.displayNum_pager_wrap

select option:disabled {
  color: #bbb;
}

//loading
.loading_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  .loading_bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 101;
  }
  .loadingInner_wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 102;

    img {
      animation: rotateImg 1s linear infinite;
      width: 110px;
    }
    .innerText {
      font-size: 30px;
      font-weight: bold;
      color: #ffffff;
    }
  }
  @media (max-width: 767px) {
    .loadingInner_wrap {
      img {
        width: 90px;
        height: auto;
      }
      .innerText {
        font-size: 24px;
      }
    }
  }
  @keyframes rotateImg {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

/*
@keyframes 任意の名前 {
  0% {
      CSSプロパティ:値;
  }
  100% {
      CSSプロパティ:値;
  }
}
*/
