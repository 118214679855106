.modal_wrap {
  .modalbody_wrap {
    .modalInnerBody_wrap {

      &.reservationListModal_wrap {
        max-width: 800px;

        .table_wrap {
          border-collapse: collapse;

          .table_row {
            .table_cell {
              font-size: 16px;
              padding: 10px;
              border: 1px solid #E2E3E2;

              &:first-of-type {
                background-color: #ED6C00;
                color: #ffffff;
                width: 180px;
              }
              &:last-of-type {
                .reservedItem_wrap {
                  &:not(:first-of-type) {
                    margin-top: 20px;
                    padding-top: 20px;
                    border-top: 1px solid #FFD9B9;
                  }
                  .itemDetail_wrap {
                    flex: 1;
                    margin-right: 10px;
                  }
                  .itemInnerBtn_wrap {
                    flex-basis: 120px;
                    width: 120px;
                  }
                  &.reservedNumber {
                    display: flex!important;
                    color: #999999!important;

                    .itemDetail_wrap {
                      flex: none;
                    }
                  }
                }
              }
              &.customCell {
                padding: 0;

                .innerCell_scrollArea {
                  padding: 10px;
                  // max-height: 250px;
                  height: 250px;
                  overflow-y: auto;
                }
              }
            }
          }//.table_row
        }//.table_wrap

        .btn_wrap{
          >div {
            &:first-of-type {}
            &:last-of-type {
              button {
                width: auto;
                padding: 0 20px;
                display: flex;

                >span {
                  display: block;
                  margin-left: 10px;
                }
                img {
                  &.imgIconAdd {
                    display: block;
                  }
                  &.imgIconAddHover {
                    display: none;
                  }
                }
                &:hover {
                  img {
                    &.imgIconAdd {
                      display: none;
                    }
                    &.imgIconAddHover {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }

        @media screen and (max-width: 991px) {
          .table_wrap .table_row .table_cell {
            font-size: 14px;
          }
        }
        @media screen and (max-width: 767px) {
          .table_wrap {
            display: block;

            .table_row {
              display: block;

              .table_cell {
                display: block;
                width: 100%!important;

                &:last-of-type {
                  .reservedItem_wrap .itemInnerBtn_wrap {
                    flex-basis: 100px;
                    width: 100px;
                  }
                  .reservedItem_wrap {
                    // display: block;


                    .itemInnerBtn_wrap {
                      display: -webkit-flex; display: -ms-flexbox; display: flex;
                      -webkit-align-items: center; -ms-flex-align: center; align-items: center;
                      //flex: auto;
                      //width: 100%;
                      //margin-top: 10px;

                      .divide10 {
                        display: none;
                      }
                      button {
                        flex-basis: 90px;
                        width: 90px;

                        &:last-of-type {
                          margin-left: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        @media screen and (max-width: 479px) {
          .table_wrap .table_row .table_cell {
            font-size: 10px;
          }
          .btn_wrap {
            -webkit-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-wrap: wrap;
            >div {
              flex-basis: 100%;
              width: 100%;

              &:first-of-type {
                order: 2;
                margin-top: 10px;
              }
              &:last-of-type {
                order: 1;
              }

              button {
                margin: 0 auto;
                width: 156px;
              }
            }
          }
        }
      }//.reservationListModal_wrap

    }//.modalInnerBody_wrap
  }//.modalbody_wrap
}//.modal_wrap