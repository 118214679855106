.modal_wrap {
  @mixin flex_box() {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  @mixin flex_align_start() {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  @mixin flex_align_stretch() {
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  @mixin flex_align_center() {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  @mixin flex_align_end() {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  @mixin flex_space_between() {
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: justify;
  }
  @mixin flex_content_center() {
    -webkit-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
  }
  @mixin flex_content_end() {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: end;
  }
  @mixin flex_content_around() {
    -webkit-justify-content: space-around;
    justify-content: space-around;
    -ms-flex-pack: distribute;
  }
  @mixin flex_container_wrap() {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
  }

  .modalbody_wrap {
    .modalInnerBody_wrap {
      &.releaseModal_wrap {
        max-width: 940px;
        //max-height: 700px;
        max-height: 90%;
        overflow-y: auto;

        h2 {
          border-bottom: 1px solid #ED6C00;
        }

        .invalid-message {
          color: #ff0000;
        }

        .table_wrap {
          .filterEle {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            z-index: 1;
          }

          border-collapse: collapse;

          .table_row {
            .table_cell {
              font-size: 16px;
              padding: 10px;
              border: 1px solid #E2E3E2;

              &:first-of-type {
                width: 180px;
              }

              &:last-of-type {
              }

              .dropzone_wrap {
                .dropzoneInner {
                  .background_wrap {
                    position: relative;
                    height: 150px;
                    border: 1px dashed #ED6C00;
                    background: #fff;

                    .background_child_wrap {
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      right: 0;
                      left: 0;
                      margin: auto;
                      height: 5rem;

                      .justify_center {
                        justify-content: center;
                        margin-bottom: 10px;
                      }
                    }
                  }
                }
              }
              // 区画図PDF用
              &.andIcon {
                img {
                  &.imgIcon {
                    height: 30px;
                    width: auto;
                    margin-right: 10px;
                  }
                }
              }
            }

            .table_img {
              $thumbSize: 115px;

              .thumb_wrap {
                flex-basis: $thumbSize;
                width: $thumbSize;
                height: $thumbSize;
                margin: 0 15px 15px 0;
                border: 1px solid #E2E3E2;

                > div {
                  width: 100%;
                  height: 100%;

                  @include flex_box();
                  @include flex_align_center();
                  @include flex_content_center();

                  img {
                    max-height: 100%;

                    &.img_checkIcon {
                      position: absolute;
                      top: 5px;
                      left: 5px;
                      background-color: #ffffff;
                      z-index: 2;
                    }
                  }
                }
              }
            }

            //.table_img
          }

          //.table_row
        }

        //.table_wrap

        .documentList_wrap {
          border: 1px solid #E2E3E2;
          max-height: 275px;
          overflow-y: auto;

          .documentListInner_wrap {
            padding: 10px;

            &:not(:first-of-type) {
              border-top: 1px solid #E2E3E2;
            }

            > div {
              &:first-of-type {
                //チェックボックス
                flex-basis: 30px;
                width: 30px;
              }

              &:nth-of-type(2) {
                //ファイル名
                flex: 1;
                margin: 0 10px;
              }

              &:nth-of-type(3) {
                //名前
                flex-basis: auto;
              }

              /**
               * もしかしたら4つ目に日時が来るかもしれないのでlast-of-typeにはしないでおく
               */
            }
          }
        }

        //.documentList_wrap
        .btn_wrap {
          > div {
            &.flex_box {
              > div {
                &:first-of-type {
                  margin-right: 10px;
                }
              }
            }
          }

          button {
            width: 130px;
          }
        }

        @media screen and (max-width: 991px) {
          .table_wrap .table_row .table_cell {
            font-size: 14px;
          }
        }
        @media screen and (max-width: 767px) {
          max-height: none;
          .table_wrap {
            display: block;

            .table_row {
              display: block;

              .table_cell {
                display: block;
                width: 100% !important;
              }
            }
          }
          .btn_wrap button {
            width: 110px;
          }
        }
        @media screen and (max-width: 479px) {
          .table_wrap .table_row .table_cell {
            font-size: 10px;
          }
          .btn_wrap {
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            -ms-flex-wrap: wrap;

            > div {
              flex: 100%;
              width: 100%;

              &:first-of-type {
                order: 2;
                margin-top: 10px;
              }

              &.flex_box {
                order: 1;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                -ms-flex-wrap: wrap;

                > div {
                  flex-basis: 100%;
                  width: 100%;

                  &:first-of-type {
                    order: 2;
                    margin-right: 0;
                    margin-top: 10px;
                  }

                  &:last-of-type {
                    order: 1;
                  }
                }
              }

              button {
                margin: 0 auto;
                width: 156px;
              }
            }
          }
        }
      }

      //.reservationEditModal_wrap
    }

    //.modalInnerBody_wrap
  }

  //.modalbody_wrap
}

//.modal_wrap
