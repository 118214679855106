.content_wrap {
  &.prefecture_cont {
    .contentInner_wrap {
      .tableArea.masterTableStyle .table_wrap .table_row {
        &.table_head .table_cell {
          &:first-of-type {width:50px;}
          &:nth-of-type(2) {width:auto;}
          &:last-of-type {
            width:95px;
          }
        }
        &.table_body .table_cell .btn_wrap > div:last-of-type {
          margin-left: 0;
        }
      }
    }
  }
}