.modal_wrap {
  .modalbody_wrap {
    .modalInnerBody_wrap {

      //メッセージ系ダイアログ
      &.msgModal_wrap {
        .msg_wrap {
          border-bottom: 1px solid #ED6C00;
          padding-bottom: 10px;

          >div {
            &:not(:first-of-type) {
              margin-top: 5px;
            }
          }
        }
      }//.msgModal_wrap

    }//.modalInnerBody_wrap
  }//.modalbody_wrap
}//.modal_wrap