.login_wrap {
  height: 100%;

  .loginInner_wrap {
    width: 100%;
    max-width: 400px;
    padding: 0 20px;

    .systemName {
      font-size: 24px;
      margin-bottom: 40px;
    }
    .input_wrap {
      margin-bottom: 20px;
    }
    .keepLogin_wrap {
      margin-bottom: 20px;

      .keepLoginInner_wrap {
        >div {
          &.innerImg {
            flex-basis: 30px;
            width: 30px;

            &.noCheck {display: block;}
            &.checked {display: none;}
          }
          &.innerText {
            margin-left: 10px;
          }
        }
        &.checked {
          >div {
            &.innerImg {
              &.noCheck {display: none;}
              &.checked {display: block;}
            }
          }
        }
      }
    }
    .btn_wrap {
      margin-bottom: 20px;

      button {
        //font-size: 20px;
        //width: 180px;
        //height: 50px;
        //line-height: 50px;
        //border-radius: 25px;
        margin: 0 auto;
      }
    }
    .forgetPass {
      >div {
        &:last-of-type {
          margin-left: 5px;
          button {
            font-size: inherit;
            text-decoration: underline;
            font-weight: bold;
            color: #ED6C00;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .loginInner_wrap {
      .systemName {
        font-size: 22px;
      }
      .keepLogin_wrap .keepLoginInner_wrap > div.innerImg {
        flex-basis: 20px;
        width: 20px;
      }
      //.btn_wrap button {
      //  font-size: 16px;
      //  width: 160px;
      //  height: 40px;
      //  line-height: 40px;
      //  border-radius: 20px;
      //}
    }
  }
  @media (max-width: 767px) {
    // height: 100vh;
    padding-top: 100px;
    display: block!important;

    .loginInner_wrap {
      margin: 0 auto;
    }
  }
  @media (max-width: 479px) {
    .loginInner_wrap {
      .systemName {
        font-size: 20px;
        margin-bottom: 30px;
      }
      .keepLogin_wrap .keepLoginInner_wrap > div.innerImg {
        flex-basis: 15px;
        width: 15px;
      }
      //.btn_wrap button {
      //  font-size: 14px;
      //  width: 140px;
      //  height: 35px;
      //  line-height: 35px;
      //  border-radius: 17px;
      //}
    }
  }
}//.login_wrap