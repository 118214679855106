.modal_wrap {
  .modalbody_wrap {
    .modalInnerBody_wrap {

      //メッセージ系ダイアログ
      &.alertModal_wrap {
        .msg_wrap {
          border-bottom: 1px solid #ED6C00;
          padding-bottom: 10px;
          > div {
            &:not(:first-of-type) {
              margin-top: 5px;
            }
          }
        }
        .url_wrap {
          border: 1px solid #ED6C00;
          border-radius: 5px;
          padding: 5px 10px;

          > div {
            &:first-of-type {
              flex: 1;
              overflow: hidden;

              > p {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            &:last-of-type {
              $btnSize: 30px;
              flex-basis: $btnSize;
              width: $btnSize;
              height: $btnSize;

              button {
                width: 100%;
                display: flex;

                img {
                  width: 15px;

                  &.img_def {display: block;}
                  &.img_hov {display: none;}
                }

                &:hover {
                  img {
                    &.img_def {display: none;}
                    &.img_hov {display: block;}
                  }
                }
              }
            }
          }
        }//.url_wrap
      }

      //.msgModal_wrap

    }

    //.modalInnerBody_wrap
  }

  //.modalbody_wrap
}

//.modal_wrap
