.modal_wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  .modal_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0,0,0,0.5);
  }

  .modalbody_wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;

    .modalInnerBody_wrap {
      width: 90%;
      height: auto;
      max-width: 400px;
      max-height: 400px;
      overflow-y: auto;
      padding: 20px;
      background-color: #ffffff;
      border: 3px solid #ED6C00;
      border-radius: 5px;

    }//.modalInnerBody_wrap
  }//.modalbody_wrap

  @media (max-width:  767px) {
    .modalbody_wrap .modalInnerBody_wrap {
      max-height: none;
    }
  }
  @media (max-width:  479px) {
    .modalbody_wrap .modalInnerBody_wrap {
      width: 95%;
      padding: 15px;

      //もし個別に対応するなら、↓の記述は'reservationEditModal_wrap'用
      max-height: 85%!important;
      overflow-y: auto;
    }
  }


  //マスタ管理系　編集モーダル
  .modalbody_wrap .modalInnerBody_wrap {
    max-width: 500px;
    max-height: none;

    &.masterEdit_modal {
      .masterEditInner_modal {
        &.table_wrap {
          .table_row {
            .table_cell {
              padding: 10px;
              border: 1px solid #E2E3E2;

              &:first-of-type {
                background-color: #ED6C00;
                color: #ffffff;
                width: 7em;
              }
            }
          }
        }
      }

      //ユーザマスタ
      &.masterUserEdit_modal {
        .masterEditInner_modal {
          &.table_wrap {
            .table_row {
              .table_cell {
                &:first-of-type {
                  width: 9em;
                }
              }
            }
          }
        }
      }//ユーザマスタ

    }
  }
}//.modal_wrap