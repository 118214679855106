.body_wrap .content_wrap .contentInner_wrap .searchArea {
  &.masterCity {
    .select_wrap {
      >div {
        &:first-of-type {
          flex-basis: 5em;
          width: 5em;
        }
        &:last-of-type {
          margin-left: 10px;
        }
      }
    }
  }
}