.modal_wrap {
  .modalbody_wrap {
    .modalInnerBody_wrap {

      //メッセージ系ダイアログ
      &.alertModal_wrap {
        &.alertFuelModal_wrap {
          width: auto;
          max-width: 90%;
          max-height: 90%;

          .msg_wrap {
            border-bottom: 1px solid #ED6C00;
            padding-bottom: 10px;

            >div {
              &:not(:first-of-type) {
                margin-top: 5px;
              }
            }
          }

          @media (max-width:  767px) {
            .msg_wrap {
              overflow-x: auto;
            }
          }

        }//.alertFuelModal_wrap
      }//.msgModal_wrap

    }//.modalInnerBody_wrap
  }//.modalbody_wrap
}//.modal_wrap


/**
 * 動的に挿入されるstyle対策
 */
body {
  margin: 0!important;
  background-color: rgba(255,255,255,0)!important;
}
/**
 * 動的に挿入されるstyle対策 end
 */