.body_wrap .content_wrap .contentInner_wrap .searchArea {
  &.masterUser {
    .userName_wrap {
      >div {
        &:last-of-type {
          margin-left: 10px;
          flex: 1;

          input {
            max-width: 20em;
          }
        }
      }
    }
    .masterInput_wrap {
      .userName_wrap {
        flex-basis: 350px;
        width: 350px;
      }
    }
    
    @media (max-width: 767px) {
      .masterInput_wrap .userName_wrap {
        flex-basis: 220px;
        width: 220px;
      }
    }
    @media (max-width: 479px) {
      .masterInput_wrap .userName_wrap {
        flex-basis: 100%;
        width: 100%;
      }
    }
  }
}